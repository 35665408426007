document.addEventListener("click", function (e) {
    if (e.target.matches(".js-blog-btn")) {
        var btns = document.querySelectorAll(".js-blog-btn");
        btns.forEach(function (it) {
            it.classList.remove("active");
        });
        e.target.classList.add("active");

        filterBlog(e.target.getAttribute("data-cat"));
    }
    else if (e.target.matches(".js-blog-filter")) {
        document.querySelector(".filter-categories").classList.toggle("open");
    }
});

function filterBlog(cat) {
    var arts = document.querySelectorAll(".blog-articles .article-card");

    arts.forEach(function (it) {
        if (cat=="all" || it.getAttribute("data-cat").indexOf(cat) != -1) {
            it.style.display = "block";
        }
        else {
            it.style.display = "none";
        }
    });
}